<template>
    <li>
        <div class="inner">
            <nuxt-link
                :to="$prismic.linkResolver(lender)"
                :title="lender.data.title"
                class="container container-logo"
            >
                <PrismicImage
                    :img="lender.data.logo"
                    :alt="lender.data.title"
                    w="200"
                    h="60"
                />
            </nuxt-link>

            <div class="container container-list">
                <slot></slot>
            </div>

            <div
                v-if="pros.length"
                class="container container-pros"
            >
                <ProsList :pros="pros" />
            </div>

            <div class="container container-buttons">
                <ButtonNuxt
                    :link="lender"
                    :text="$store.state.translations.read_more1 || 'Läs mer'"
                    size="read-more"
                    icon="fas fa-arrow-righ"
                />

                <ButtonTarget
                    v-if="$helpers.getAffiliateLink($store.state.currentSiteLocale, lender)"
                    :link="lender"
                    size="sm"
                    icon="fas fa-shield"
                    bg="orange"
                />

                <button
                    v-else
                    type="button"
                    name="button"
                    class="btn btn-disabled btn-sm"
                >
                    N/A
                </button>

                <span v-if="$store.state.settings.ad_link_compliance_enabled">{{ $store.state.settings.ad_link_compliance_text }}</span>
                <!-- TODO: Something like this from the Core -->
                <!-- <span>234 andra har valt det här lånet senaste 7 dagarna</span> -->
            </div>

            <div
                v-if="$store.state.settings.sweden_warning_enabled && parseFloat(lender.data.detail_rate_highest) >= 39"
                class="container container-warning"
            >
                <i  class="fas fa-exclamation-triangle"  />
                <p>
                    <strong>Det här är en högkostnadskredit</strong><br />
                    Om du inte kan betala tillbaka hela skulden riskerar du en betalningsanmärkning. För stöd, vänd dig till budget- och skuldrådgivningen i din kommun. Kontaktuppgifter finns på hallåkonsument.se.
                </p>
            </div>
        </div>

        <div
            v-if="$store.state.settings.compliance_enabled && lender.data.compliance_example_loan"
            class="container container-att"
        >
            {{ lender.data.compliance_example_loan }}
        </div>
    </li>
</template>

<script>
import TableList from './components/TableList.vue'
import ProsList from './components/ProsList.vue'
export default {
    components: { ProsList, TableList },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.id]
        },

        pros() {
            return this.lender.data.pros.filter((e) => {
                return e.pro;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
li.item-card,
li.item-data {
    .inner {
        box-shadow: $bxs;
        @include border-radius(10px);
        background: $white;

        .container {
            @include spacing(padding, 5);

            @include device(mobile) {
                width: 100%;
            }

            &-logo {
                @include flex;

                img {
                    width: auto;
                    max-width: 100%;
                }
            }

            &-buttons {
                @include grid(1, 10px);

                .btn {
                    text-align: center;
                    width: 100%;
                    box-sizing: border-box;
                }

                span {
                    font-size: 14px;
                    color: #a0a0a0;
                    text-align: center;
                }
            }

            &-warning {
                @include flex(start,start);
                @include border(top);

                i {
                    font-size: 42px;
                    margin-right: 10px;
                    color: #b30000;
                }
                p {
                    flex: 1;
                    @include font-size(14px);

                    strong {
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .container-att {
        @include font-size(14px);
        margin-top: 10px;
    }
}

li.item-card {
    .inner {

        .container {
            &-buttons {
                @include border(top);
            }
        }
    }
}

li.item-data {
    .inner {
        @include flex;

        .container {
            &-list {
                width: 100%;

                @include device(desktop) {
                    width: auto;
                    flex: 1;
                }
            }

            &-buttons {
                width: 100%;

                @include device(pad) {
                    max-width: 300px;
                }
            }

            &-pros {
                @include spacing(padding,0);
                @include flex;
                order: 9;
                width: 100%;
                @include border(top);

                .pros-list::v-deep {
                    width: 100%;
                    @include flex;

                    li {
                        @include spacing(margin,2);
                    }
                }
            }
        }
    }
}
</style>
