<!-- TODO: Move this to vue-components -->
<template>
    <div
        class="tooltip"
        @mouseenter="showTooltip(true)"
        @mouseleave="showTooltip(false)"
    >
        <i
            @click="show =! show"
            class="click fas fa-info-circle blue"
            :class="size"
        />

        <div
            v-if="show"
            class="tooltip-text"
        >
            {{text}}
        </div>

        <div
            @click="show =! show"
            v-if="show"
            class="tooltip-overlay"
        />
    </div>
</template>

<script>
  export default {
      props: {
          text: {
              type: String
          },
          size: {
              type: String,
              default: 'p',
              required: false
          }
      },
      data() {
          return {
              show: false
          }
      },
      watch: {
          show () {
              if(window.innerWidth < 600) document.body.style.overflow = this.show ? 'hidden' : ''
          }
      },
      methods: {
          showTooltip(val) {
              if(window.innerWidth > 600) this.show = val
          }
      }
  }
</script>
<style lang="scss" scoped>
    .tooltip {
        position: relative;

        i {
            position: relative;
            z-index: 0;
        }

        &-text {
            @include grid(1, 20px);
            @include font-size(14px);
            @include spacing(padding, 4);
            @include border-radius(5px);
            z-index: 100;
            position: fixed;
            right: 10px;
            bottom: 50%;
            transform: translateY(-50%);
            width: calc(100% - 40px);
            background: #002E68;
            color: #fff;

            @include device(pad) {
                position: absolute;
                width: 200px;
                bottom: calc(100% - 5px);
                right: calc(100% - 5px);
                transform: translateY(0%);
            }
        }

        &-overlay {
            @include size(100vw,100vh);
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(0,0,0,.5);
            z-index: 99;

            @include device(pad) { display: none; }
        }
    }
</style>
