<template>
    <ItemBase
        :id="id"
        class="item-data"
    >
        <TableList :lender="lender" />
    </ItemBase>
</template>

<script>
import ItemBase from '../ItemBase.vue';
export default {
    components: { ItemBase },
    extends: ItemBase,
    props: {
        id: {
            type: String,
            required: true
        }
    }
}
</script>
