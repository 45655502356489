<template>
    <ul class="table-list">
        <li>
            <span>{{ $store.state.translations.ll_loan_amount || 'Lånebelopp' }}</span>
            <span>{{lender.data.detail_amount_string}}</span>
        </li>

        <li>
            <span>{{ $store.state.translations.ll_term_loans || 'Löptid' }}</span>
            <span>{{lender.data.detail_duration_string}}</span>
        </li>

        <li>
            <span>{{ $store.state.translations.ll_interest || 'Ränta' }}</span>
            <span>{{lender.data.detail_rate_string}}</span>
        </li>

        <li v-if="this.$store.state.settings.list_info_version == 'netherlands'">
            <span>{{ $store.state.translations.ll_bkr_test || 'BKR Toetsing' }}</span>
            <span>{{ this.lender.data.bkr_toetsing ? $store.state.translations.yes || 'Yes' : $store.state.translations.no || 'No' }}</span>
        </li>

        <li v-if="this.$store.state.settings.list_info_version == 'sweden'">
            <span>{{ $store.state.translations.ll_credit_issuer || 'Kreditupplysare' }}</span>
            <span>{{lender.data.detail_credit_check_company}}</span>
        </li>

        <li v-if="this.$store.state.settings.list_info_version == 'sweden'">
            <span>{{ $store.state.translations.ll_payment_remark_accepted || 'Betalningsanm. Ok' }}</span>

            <div>
                <span v-if="lender.data.detail_payment_remarks_ok" class="highlight">
                    {{ $store.state.translations.yes || 'Ja' }}
                </span>
                <span v-else>
                    {{ $store.state.translations.no || 'Nej' }}
                </span>
                <Tooltip
                    v-if="lender.data.detail_payment_remarks_description"
                    size="p"
                    :text="lender.data.detail_payment_remarks_description"
                    class="tooltip"
                />
            </div>
        </li>

        <li v-if="this.$store.state.settings.list_info_version == 'sweden'">
            <span>{{ $store.state.translations.ll_payout_time || 'Utbetalningstid' }}</span>
            <div>
                <span>{{lender.data.payout_time_data}}</span>
                <Tooltip
                    v-if="lender.data.payout_time_description"
                    size="p"
                    :text="lender.data.payout_time_description"
                    class="tooltip"
                />
            </div>
        </li>
    </ul>
</template>
<script>
import Tooltip from '@/components/Tooltip.vue'
export default {
    components: { Tooltip },
    props: {
        lender: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
    .tooltip {
        @include spacing(margin, 2, left);
        color: $blue;
    }

    // General
    .table-list {
        li {
            @include font-size(14px);

            span {
                &:last-child {
                    text-align: right;
                    flex: 1;
                    font-weight: bold;
                    padding-left: 10px;
                }
            }

            div {
                @include flex(end);
                font-weight: bold;
            }
        }
    }

    // Item specific
    .item-card {
        .table-list {
            @include flex;

            li {
                width: 100%;
                padding: 10px 0;
                @include flex(between);
                @include border(bottom);
                &:last-child { border-bottom: 0; }
            }
        }
    }

    .item-data {
        .table-list {
            width: 100%;

            @include device(desktop) {
                width: auto;
                flex: 1;
                gap: 30px;
                column-count: 2;
            }

            li {
                padding: 10px 0;
                @include flex(between);
                @include border(bottom);
                &:last-child { border-bottom: 0; }

                @include device(desktop) {
                    border: 0;
                    &:nth-child(odd) { @include border(bottom); }
                }
            }
        }
    }
</style>
