<template>
    <ul class="pros-list">
        <li
            v-for="(pro, index) in pros"
            :key="index"
        >
            {{pro.pro}}
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        pros: {
            type: Array,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
    ul.pros-list {
        li {
            position: relative;
            padding-left: 25px;
            font-weight: bold;
            @include font-size(14px, 16px);

            @include psuedo(after, "\f058") {
                position: absolute;
                left: 0;
                top: 0;
                font-family: 'icomoon';
                color: #4eb447;
                font-size: 18px;
            }
        }
    }
</style>
